<template>
  <v-dialog v-model="mode.play" :fullscreen="isMobile()"
            :transition="getTransition()" content-class="theme-youtube-dialog">
    <v-card v-if="mode.play">
      <v-layout class="align-center justify-center">
        <v-flex xs12>
          <a class="youtube-subscribe" href="https://www.youtube.com/channel/UC2TdzOaEpqspVcTErCSKCYQ?sub_confirmation=1" target="_blank">
            {{ $t("subscribe") }}
          </a>
          <a class="youtube-close" @click="closeVideo()" v-if="isNotIOs()">
            <v-icon>{{icons.close}}</v-icon>
          </a>
          <div class="video-background">
            <div>
              <youtube :video-id="videoId" :player-vars="options" @ended="ended"
                       @paused="paused" @ready="ready" @playing="playing"/>
            </div>
          </div>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mdiClose} from '@mdi/js'
  import Vue from 'vue';
  import VueYouTubeEmbed from 'vue-youtube';

  Vue.use(VueYouTubeEmbed);

  export default {
    name: 'youtubePlayer',
    props: {
      videoId: undefined,
      playerId: undefined,
    },
    data() {
      return {
        player: undefined,
        mode: {
          play: false,
        },
        options: {
          showinfo: 0,
          modestbranding: 1,
          encryptedMedia: 1,
          rel: 0,
        },
        icons: {
          close: mdiClose
        }
      };
    },
    mounted() {
      this.globalEvents.$on('youtubePlayerStart_'.concat(this.playerId), () => {
        this.playVideo();
      });
    },
    methods: {
      playVideo() {
        this.mode.play = true;
      },
      closeVideo() {
        this.mode.play = false;
      },
      isNotIOs() {
        const { platform } = navigator.platform;
        return platform !== 'iPad' && platform !== 'iPhone'
          && platform !== 'iPod';
      },
      ready(player) {
        this.player = player;
        if (this.mode.play) {
          this.player.mute();
          this.player.playVideo();
        }
      },
      ended() {
        this.mode.play = false;
      },
      playing() {
        this.mode.play = true;
      },
      paused() {
        if (this.isMobile()) {
          this.mode.play = false;
        }
      },
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      },
      getTransition() {
        return this.isMobile() ? 'dialog-bottom-transition' : 'dialog-transition';
      },
    },
  };
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";

  .theme-youtube-dialog {
    overflow: hidden;
    width: auto !important;
    border-radius: 0;

    /* IE11 */
    @media all and (-ms-high-contrast:none)
    {
      width: 731px !important;
    }

    @include MQ(XS) {
      width: 100% !important;
    }

    .video-background {
      @include MQ(XL) {
        min-height: 411px;
        min-width: 731px;
      }
      @include MQ(L) {
        min-height: 411px;
        min-width: 731px;
      }
      @include MQ(M) {
        min-height: 411px;
        min-width: 731px;
      }
      @include MQ(S) {
        min-height: 338px;
        min-width: 600px;
      }
      @include MQ(XS) {
        height: 100%;
        width: 100%;
      }
      top: 0;
      z-index: 1;
      div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        iframe {
          width: 100%;
          height: 100%;
          /*width: 100%;
          top: 0;
          left: 0;
          position: absolute;
          height: 100%;*/
        }
      }
    }

    .youtube-subscribe {
      background-color: #cc181e;
      color: white;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1000;
      text-decoration: none;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      svg {
        fill: white;
        width: 20px;
        margin-right: 10px;
      }
    }

    .youtube-close {
      background-color: white;
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px 10px;
      z-index: 1000;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      .v-icon {
        color: $primary-text;
      }
    }
  }
</style>
